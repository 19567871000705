export const environment = {
  production: false,
  batchSdnCalls: process.env['NX_PUBLIC_BATCH_SDN_CALLS'] !== '0',
  appVersion: process.env['NX_PUBLIC_APP_VERSION'],
  appEnv: process.env['NX_PUBLIC_APP_ENV'],
  spinsLlcId: '0013p000029NprlAAC',
  powerTabsSfID: 'a56Dm000000XxtPIAS',
  panelDataSfID: 'a56TR0000003srtYAA',
  panelDataIframeUrl:
    'https://spinsllc.auth0.com/samlp/sT8Wtkhc67OUzaSvrzuHGFtHaS1fXqQH?RelayState=https://advantageprep.iriworldwide.com/unify-client/sso.html',
  attlabSfid: ['a56Dm000000LI27IAG', 'a56Dm000000LI2IIAW'],
  remoteAdminUrl: 'https://admin-dot-spins-insights-portal-uat.uc.r.appspot.com/',
  remoteRetailInsightsUrl: 'https://uat.retail-platform.spins.team',
  remoteBrandInsightsUrl: 'https://uat.brand-insights.spins.team',
  remoteIgnitePlusUrl: 'https://uat.ignite-plus.spins.team',
  remotePartnerInsightsUrl: 'https://spins-partner-insights-uat.uc.r.appspot.com/',
  remotePowerTabsUrl: 'https://uat.powertabs.spins.team/',
  spinsDigestUrl: 'https://uat.digest-ui.spins.team',
  onMaintenance: false,
  allowedCompanyTypesDefaultFilters: ['RETAILER'],
  proxyServer: 'https://proxy-server-uat-cr-305354901148.us-central1.run.app',
  ignitePlusSfID: ['a56TR0000006bGDYAY', 'a56VE000000Z8vZYAS'],
  itemAttributeCodingSfID: 'a56TH0000009j2jYAA',
};
