import { setRemoteDefinitions } from '@spins-delivery-platform/load-remote-module';
import { environment } from './environments/environment';

import('./bootstrap');

const definitions = {
  admin: environment.remoteAdminUrl,
  child: environment.remoteBrandInsightsUrl,
  'brand-insights-ui': environment.remoteBrandInsightsUrl,
  'retail-insights-ui': environment.remoteRetailInsightsUrl,
  'partner-insights-ui': environment.remotePartnerInsightsUrl,
  'bi-powertabs': environment.remotePowerTabsUrl,
  'digest-ui': environment.spinsDigestUrl,
  'ignite-plus-ui': environment.remoteIgnitePlusUrl,
};

setRemoteDefinitions(definitions);
import('./bootstrap').catch((err) => console.error(err));
